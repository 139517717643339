import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value
      });
    }
  ];
}

export function useFormChecks(initialState) {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    function (event) {
      setValues({
        ...fields,
        [event.target.id]: !fields[event.target.id]
      });
    }
  ];
}


export function useAttributeFields(initialState) {
  const [fields, setValues] = useState(initialState);
  // Expecting an update of [
  // {Name: "", Value: "" }, ..., {}
  //]
  return [
    fields,
    function (mapOfAttributes, oldAttributes) {
      var result = mapOfAttributes.reduce(function(map, obj) {
        map[obj.Name.replace('custom:', '').replace('given_name', 'firstName').replace('family_name', 'lastName')] = obj.Value;
        return { ...oldAttributes, ...map }
      }, {});
      setValues({
        ...result,
      });
    }
  ];
}