import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "./components/Auth/AsyncComponent";
import AppliedRoute from "./components/Auth/AppliedRoute";
import AuthenticatedRoute from "./components/Auth/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/Auth/UnauthenticatedRoute";

const AsyncHome = asyncComponent(() => import("./containers/Home"));
const AsyncLogin = asyncComponent(() => import("./containers/Login"));
const AsyncSignup = asyncComponent(() => import("./containers/Signup"));
const AsyncNotFound = asyncComponent(() => import("./containers/NotFound"));
const AsyncEvent = asyncComponent(() => import("./containers/Event"));
const AsyncWebSocketTest = asyncComponent(() => import("./containers/WebSocketTest"));
const AsyncEventAdminView = asyncComponent(() => import("./containers/EventAdminView"));
const AsyncResetPassword = asyncComponent(() => import("./components/Auth/ResetPassword"));
const AsyncLiveEvent = asyncComponent(() => import("./components/Live/LiveEvent"));
const AsyncPricing = asyncComponent(() => import("./components/Home/Pricing"));
const AsyncProduct = asyncComponent(() => import("./components/Product/Product"));
const AsyncUpgradeSuccess = asyncComponent(() => import("./components/Billing/UpgradeSuccess"));


export default function Routes({ appProps, cookies }) {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={AsyncHome} appProps={appProps} cookies={cookies} />
      <UnauthenticatedRoute path="/login" exact component={AsyncLogin} appProps={appProps} />
      <UnauthenticatedRoute path="/signup" exact component={AsyncSignup} appProps={appProps} />
      <UnauthenticatedRoute path="/login/reset" exact component={AsyncResetPassword} appProps={appProps}/>
      <UnauthenticatedRoute path="/event/:id" exact component={AsyncEvent} appProps={appProps} />
      <AuthenticatedRoute path="/socket/:id" exact component={AsyncWebSocketTest} appProps={appProps} />
      <AuthenticatedRoute path="/admin/:id" exact component={AsyncEventAdminView} appProps={appProps} />
      <UnauthenticatedRoute path="/live/:id" exact component={AsyncLiveEvent} appProps={appProps} />
      <UnauthenticatedRoute path="/pricing" exact component={AsyncPricing} appProps={appProps} />
      <AuthenticatedRoute path="/success" exact component={AsyncUpgradeSuccess} appProps={appProps} />
      <UnauthenticatedRoute path="/product" exact component={AsyncProduct} appProps={appProps} />

      {/* Finally, catch all unmatched routes */}
      <Route component={AsyncNotFound} />
    </Switch>
  );
}
