//***LOCATION fields need to change if you delete your backend
// TODO: Is it possible to use generic names to avoid any changes.

const dev = {
  STRIPE_KEY: "pk_test_GL1RHNn2nIuPoZxphrKiXEiU",
  s3: {
    REGION: "us-east-2",
    BUCKET: "askrunner-api-dev-attachmentsbucket-frjorpxd3qgf" //***LOCATION
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://to23riijm2.execute-api.us-east-2.amazonaws.com/dev" //***LOCATION ServiceEndpoint
  },
  cognito: {
    REGION: "us-east-2",
    APP_CLIENT_ID: "2vv101mmf0e1aguh9qntdoakj4", //***LOCATION UserPoolClientId
    USER_POOL_ID: "us-east-2_PPWjVxKnw", //***LOCATION UserPoolId
    IDENTITY_POOL_ID: "us-east-2:bc58d0ab-0ddc-47f8-9e39-25045200b554" //***LOCATION
  },
  // Enable separate URL for Rest vs GraphQL endpoints (websockets vs Rest)
  graphQL: {
    // Talk to local running graphQL or AWS dev here
    URL: "http://localhost:3000/dev/graphql",
    GOOGLEURL: "http://localhost:3000/dev/googleGraphql", //***LOCATION ServiceEndpoint and add '/graphql'
    UNA_URL: "http://localhost:3000/dev/unaGraphql",
    WSURL: 'ws://localhost:3001'
    // Run locally to save development time (dircet access to MySQL).
    // Run in AWS to test closer to prod environment.
  }
};

const prod = {
  STRIPE_KEY: "pk_live_AbnsMM4nTZJHpoIpL9yc66lg",
  s3: {
    REGION: "us-east-2",
    BUCKET: "askrunner-api-prod-attachmentsbucket-1q5dhq8gwmrs9" //***LOCATION
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://lf1zie0m6k.execute-api.us-east-2.amazonaws.com/prod" //***LOCATION ServiceEndpoint
  },
  cognito: {
    REGION: "us-east-2",
    APP_CLIENT_ID: "7sue0241npqbo3j522th0i4h8e", //***LOCATION UserPoolClientId
    USER_POOL_ID: "us-east-2_2X9ikYC3d", //***LOCATION UserPoolId
    IDENTITY_POOL_ID: "us-east-2:ecfafde8-ddb4-498f-b4d2-0a4a07541469" //***LOCATION IdentityPoolId
  },
  graphQL: {
    URL: "https://01h88yc8nj.execute-api.us-east-2.amazonaws.com/prod/graphql", //***LOCATION ServiceEndpoint and add '/graphql'
    GOOGLEURL: "https://lf1zie0m6k.execute-api.us-east-2.amazonaws.com/prod/googleGraphql", //***LOCATION ServiceEndpoint and add '/graphql'
    UNA_URL: "https://01h88yc8nj.execute-api.us-east-2.amazonaws.com/prod/unaGraphql", //***LOCATION ServiceEndpoint and add '/graphql'
    WSURL: "wss://p9zbqfoagk.execute-api.us-east-2.amazonaws.com/prod" //***LOCATION
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
