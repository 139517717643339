import React, { useState, useEffect } from "react";

import Auth from '@aws-amplify/auth';
import Cache from '@aws-amplify/cache';
import { withRouter } from "react-router-dom";
import { withCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import jwt  from 'jsonwebtoken';

import { createUser } from "./libs/user";
import { useAttributeFields } from "./libs/hooksLib";


import Routes from "./Routes";
import "./App.css";

function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [guestMode, setGuestMode] = useState(true);
  const [attributes, setAttributes] = useAttributeFields({
    firstName: null,
    lastName: null,
  });

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    const token = Cache.getItem('token');
    const credentials = Cache.getItem('credentials');
    const decodedJWT = jwt.decode(token);
    if (decodedJWT && decodedJWT.exp < Date.now()/1000) {
      handleLogout();
    };
    // If we have enough for a user, set it.
    if (token && credentials) {
      var user = createUser(
        credentials.data.IdentityId.split(':')[1],
        decodedJWT.hd,
        decodedJWT.email,
      );
      setUser(user);
      setAttributes([
        { Name: "firstName", Value: decodedJWT.given_name },
        { Name: "lastName", Value: decodedJWT.family_name },
        { Name: "picture", Value: decodedJWT.picture },
        { Name: "email", Value: decodedJWT.email },
        { Name: "iss", Value: decodedJWT.iss }
      ]);
      userHasAuthenticated(true);
      setGuestMode(false);
    };

    // If we haven't got a user, check cognito to see if we have creds.
    if (!user) {
      try {
        await Auth.currentSession().then((creds) => {
          Cache.setItem('token', creds.idToken.jwtToken);
        });
        await Auth.currentAuthenticatedUser().then(
          (user) => { setUser(user); return user; }).then(
            (user) => { return Auth.userAttributes(user); }).then(
              (attributes) => {
                setAttributes(attributes);
                userHasAuthenticated(true);
                setGuestMode(false);
              });
      }
      // If still no user then create guest user using session.
      catch (e) {
        if (!props.cookies.get('session_id')) {
          props.cookies.set('session_id', uuidv4(), { path: '/' });
        }
        setUser(createUser(
          props.cookies.get('session_id')),
          null // no token, no email domain
        )
        setAttributes([
            { Name: "firstName", Value: props.cookies.get('first_name') },
            { Name: "lastName", Value: props.cookies.get('last_name') }
        ], attributes);
      }
    }
    setIsAuthenticating(false);
  }

  function setGuestAttributesInCookie(firstName, lastName) {
    props.cookies.set('first_name', firstName, { path: '/' });
    props.cookies.set('last_name', lastName, { path: '/' });
  }

  async function handleLogout() {
    await Auth.signOut().then(() => {
      userHasAuthenticated(false);
      Cache.removeItem('token');
      Cache.removeItem('credentials');
      Cache.removeItem('isGoogle');
      setGuestMode(true);
      setUser(createUser(
        props.cookies.get('session_id')),
        null // no token, no email domain
      )
      setAttributes([
        { Name: "firstName", Value: props.cookies.get('first_name') },
        { Name: "lastName", Value: props.cookies.get('last_name') }
      ], []);
      props.history.push("/");
    })
  }

  return (
    !isAuthenticating && (
      <div className="App container">
        <Routes
          appProps={
            {
              isAuthenticated,
              userHasAuthenticated,
              user,
              setUser,
              attributes,
              setAttributes,
              handleLogout,
              guestMode,
              setGuestMode,
              setGuestAttributesInCookie
            }
          }
          cookies={ props.cookies }
        />
      </div>
    )
  );
}

export default withCookies(withRouter(App));
