export function createUser(userID, hostedDomain, email) {
  return {
    userUUID: userID,
    hostedDomain: hostedDomain,
    getUsername() {
      return this.userUUID;
    },
    // This is to keep custom users (google etc)
    // In sync with cognito users.
    attributes: {
      email: email
    }
  }
}

export function UserHasEventViewPermissions(event, user) {
  if (!event.Config.IsPrivate) {
    return true;
  }
  if (event.Config.HostedDomain === user.hostedDomain) {
    return true;
  }
  return false;
}

export function UserHasEventAdminPermissions(event, user) {
  if (!user) {
    return false
  };
  var userUUID = user.userUUID || user.username
  if (!event || !user || !userUUID || !event.CreatedBy) {
    return false
  };
  if (event.CreatedBy === userUUID) {
    return true;
  };
  return false;
};